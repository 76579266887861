<template>
  <div class="content mb-5 mx-auto">
    <p class="policy-header bold">Privacy Policy</p>
    <p>
      AutoFlip Pty Ltd ABN 87 638 825 751 ("AutoFlip" and "we" or "us") is committed to protecting the privacy of the personal
      information and sensitive information which it collects and holds.
    </p>
    <p>
      The purpose of this Privacy Policy is to:
    </p>
    <ul>
      <li>
        the kinds of information that AutoFlip may collect about you and how
        that information is held;
      </li>
      <li>
        how AutoFlip collects and holds personal information;
      </li>
      <li>
        the purposes for which AutoFlip collects, holds, uses and discloses
        personal information;
      </li>
      <li>
        the way in which you can complain about a breach of your privacy; and
      </li>
      <li>
        how you can access the personal information AutoFlip holds about you and
        seek to correct such information.
      </li>
    </ul>
    <p>In general, we collect and store two types of information:</p>
    <ul>
      <li>
        Personal information that you and other people associated with your
        organization choose to enter into our systems. This includes names, e
        mail addresses and certain financial information that is necessary to do
        business with you.
      </li>
      <li>
        Non-personal information associated with your transactions with our
        systems. This includes visitor and resiliency data, access date and
        times session identifier and activity and error logs.
      </li>
    </ul>
    <p>
      By becoming or remaining a client of AutoFlip or by providing your
      personal information to us, you consent (to the extent that we require
      your consent under privacy laws to do these things) to the AutoFlip
      collecting, using and disclosing your personal information in accordance
      with this Privacy Policy.
    </p>
    <p class="title mt-4 bold">How we hold your information</p>
    <p>
      We may store your information in hard copy or electronic format, in
      storage facilities that we own and operate ourselves, or that are owned
      and operated by our service providers. We take reasonable steps to
      maintain the security of your information and to protect it from
      unauthorised disclosures. There are many information security risks that
      we can't manage and therefore your should take appropriate care to help
      safeguard your information.
    </p>
    <p class="title mt-4 bold">How we use your information</p>
    <p>We may use your information to :</p>
    <ul>
      <li>
        To provide products and services to you, to assist you with inquiries or
        purchases, or to provide you with better service.
      </li>
      <li>
        To administer and manage the products and services we provide to you, to
        charge and bill you for them, and to collect any amounts you may owe us.
      </li>
      <li>
        To gain an understanding of your needs, to perform research and
        analysis, and to improve or develop our products and services.
      </li>
      <li>
        To provide anonymised examples of how our products and services work.
      </li>
      <li>
        To monitor system use, quality and performance, and to operate,
        maintain, develop, test and upgrade our systems.
      </li>
    </ul>
    <p>
      We may also use your information so that we can promote and market products, services and special offers that we think will be of interest to you. This marketing may be carried out in a variety of ways and may continue after you cease acquiring any products or services from us until you opt-out by contacting us at <a href="privacy@AutoFlip.com.au" target="_blank"
        >privacy@autoflip.com.au</a
      > or by using the opt out options in our communications to you.
    </p>
    <p>
      AutoFlip will only use your personal information for the purpose for which
      it was collected by AutoFlip (<span class="bold">primary purpose</span>),
      unless:
    </p>
    <ul>
      <li>
        there is another purpose (<span class="bold">secondary purpose</span>)
        and that secondary purpose is directly related to the primary purpose,
        and you would reasonably expect, or the AutoFlip has informed you, that
        your information will be used for that secondary purpose;
      </li>
      <li>
        you have given your consent for your personal information to be used for
        a secondary purpose; or
      </li>
      <li>
        AutoFlip is required or authorised by law to use your personal
        information for a secondary purpose.
      </li>
    </ul>
    <p class="title mt-4 bold">Information we share</p>
    <p>
      AutoFlip will confine its disclosure of your personal information to the
      primary purpose for which that information has been collected, or for a
      related secondary purpose. This includes when disclosure is necessary to
      help us manage, conduct and oversee the AutoFlip business, or for security
      reasons.
    </p>
    <p>We may provide your personal information to:</p>
    <ul>
      <li>
        third parties contracted to provide services to AutoFlip, such as
        entities contracted to assist in accreditation or survey processes;
      </li>
      <li>
        anyone authorised by you to receive your personal information (your
        consent may be express or implied);
      </li>
      <li>allow us to provide a product or service;</li>
      <li>
        anyone AutoFlip is required by law to disclose your personal information
        to.
      </li>
    </ul>
    <p class="title mt-4 bold">Handling of email addresses</p>
    <p>
      We do not and will not share, sell or otherwise intentionally disclose
      email addresses you enter or store in AutoFlip to anyone unless we receive
      your express written permission to do so or you have engaged with AutoFlip
      through one of our approved partners, in which case email addresses will
      be disclosed to that partner.
    </p>
    <p class="title mt-4 bold">Links to other sites</p>
    <p>
      AutoFlip may provide links to third party websites, including advertisements.  <br/><br/>
      These linked sites may not be under our control and AutoFlip is not responsible for the content
      or privacy practices employed by those websites. Before disclosing your
      personal information on any other website, we recommend that you carefully
      read the terms and conditions of use and privacy statement of the relevant
      website.
      <br/><br/>
      You also acknowledge that AutoFlip may receive a commission form a link to an advertisement if a user clicks an advertisement or purchases goods or services from the advertiser.
    </p>
    <p class="title mt-4 bold">Further information or to make a complaint</p>
    <p>
      You have a right to access your personal information which the AutoFlip
      holds about you. If you make a request to access your personal
      information, we will ask you to verify your identity and specify the
      information you require.
    </p>
    <p>
      You can also request an amendment to any of your personal information if
      you consider that it contains inaccurate information.
    </p>
    <p>
      If you require any further information about our privacy policy or you
      wish to make a complaint, please contact us at
      <a href="mailto:privacy@AutoFlip.com.au" target="_blank"
        >privacy@autoflip.com.au</a
      >.
    </p>
    <p>
      While AutoFlip aims to meet all requests for access to personal
      information, in a small number of cases and where necessary and permitted
      to do so by law, AutoFlip may not give access or may do so only under
      conditions.
    </p>
    <p>
      Subject to applicable laws, AutoFlip may destroy records containing
      personal information when the record is no longer required by AutoFlip.
    </p>
  </div>
</template>

<script>
export default {
  name: "Index",
};
</script>

<style lang="scss">
.content {
  color: #404040;
  max-width: 394px;
  padding: 35px 20px 63px;
  margin-top: 30px;
  background-color: #ffffff;
  border-radius: 20px;

  @media screen and (min-width: 768px){
    max-width: 1080px;
    padding: 45px 50px 63px;
  }

  .policy-header {
    text-align: center !important;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 12px;

    @media screen and (min-width: 768px) {
      font-size: 24px;
      line-height: 20px;
      margin-bottom: 10px;
  }
}

  .title {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 12px;

    @media screen and (min-width: 768px) {
      font-size: 24px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }

  p {
    margin-bottom: 12px;
  }

  p,
  li {
    font-size: 14px;
    font-family: Avenir Next LT W05 Thin;
    font-weight: 600;

    @media screen and (min-width: 768px){
      font-size: 16px;
  }
  }

  .bold {
    font-family: Avenir Next LT W05 Bold;
  }
}
</style>
